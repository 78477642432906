import MutationSummary from 'mutation-summary'

const script = document.currentScript
const origin = new URL(script.src, location).origin

const initWidget = element => {
  if (element.getAttribute('data-embed-code-inserted')) return
  element.setAttribute('data-embed-code-inserted', '1')

  const params = new URLSearchParams({
    partner_key:  element.dataset.partnerKey,
    external_id:  element.dataset.itemId || element.dataset.externalId,
    ein:          element.dataset.ein,
  })
  const url = `${origin}/widgets/auto/donate_or_search?${params}`
  const success = ({ widget_type, identifier }) => {
    if (![ 'donate', 'search' ].includes(widget_type))
      throw new Error('Invalid widget type', widget_type)

    const suffix = widget_type == 'search' ? '-to-donate' : ''
    const embedCode = document.createElement('div')
    embedCode.className = `plg-${widget_type}${suffix}`
    embedCode.setAttribute('data-partner-key', element.dataset.partnerKey)
    if ([ 'all', 'item_id' ].includes(identifier) && element.dataset.itemId)
      embedCode.setAttribute('data-item-id', element.dataset.itemId)
    if ([ 'all', 'ein' ].includes(identifier) && element.dataset.ein)
      embedCode.setAttribute('data-ein', element.dataset.ein)
    if (widget_type == 'search')
      embedCode.setAttribute('data-search-to-donate', 'true')
    element.appendChild(embedCode)
  }
  fetch(url, { headers: { Accept: 'application/json' } })
  .then(response => response.json()).then(success)
}

const init = () => {
  const selector = '.plg-auto-donate-or-search'
  document.querySelectorAll(selector).forEach(initWidget)

  new MutationSummary({
    callback (summaries) {
      summaries[0].added.forEach(initWidget)
    },
    queries: [{ element: selector }]
  })
}

// Spin it up on load with async powers  =]
if (document.readyState == 'loading')
  document.addEventListener('DOMContentLoaded', init)
else
  setTimeout(init, 1)
